import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CmsAccessControlDirective } from './directives/cms-access-control.directive';
import { LoaderComponent } from './../loader/loader.component';
import { NgxLoadingModule } from 'ngx-loading';
import { AddSignatureComponent } from './add-signature/add-signature.component';
import { SignaturePadModule } from 'angular2-signaturepad';
import { ContentSanitizerPipe } from './pipes/content-sanitizer.pipe';


@NgModule({
  declarations: [
    CmsAccessControlDirective,
    LoaderComponent,
    AddSignatureComponent,
    ContentSanitizerPipe,
  ],
  imports: [
  CommonModule,
  SignaturePadModule,
  NgxLoadingModule.forRoot({}),
  ],
  exports:[CmsAccessControlDirective,LoaderComponent,AddSignatureComponent,ContentSanitizerPipe]
})
export class GlobalSharedModule { }
