import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { EncryptionService } from './encryption.service';
import { environment } from 'src/environments/environment';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

  constructor(private router : Router, private encryptionService :EncryptionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (!request.url.toLocaleLowerCase().includes('/login')){
      let tokenDetails= localStorage.getItem(environment.localstorage_userDetails) ? JSON.parse(this.encryptionService.decryptUsingAES256(localStorage.getItem(environment.localstorage_userDetails) || '{}')):null;
      let access_id = tokenDetails?.internalUserResponse?.user_id;
      if(request.url!='https://jsonip.com'){
      if(access_id){
        request = this.updateRequest(request, access_id);
      }
      else {
         request = this.updateRequest(request, access_id);
          // localStorage.clear();
          // this.router.navigate(['/auth/login'])
      }
    }

      }
      return next.handle(request);
  }

  updateRequest(request: HttpRequest<any>, access_id?:any) {
    request = request.clone({
        headers: this.addExtraHeaders(request.headers, access_id)
    });
    return request;
}
addExtraHeaders(headers: HttpHeaders, access_id:any): HttpHeaders {
    if (access_id) {
        headers = headers.append('user_id', `${access_id}`);
    }
    return headers;
}
}
