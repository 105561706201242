import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
  providedIn: 'root'
})
export class EncryptionService {

  keySize = 256;
  tokenFromUI: string = "CMS_lms_ls_token";
  encrypted: any = "";
  decrypted: string;
  secratKey='123'
  constructor() { }

  // encrypt(value: string) {
  //   let crpt=CryptoJS.AES.encrypt(value, this.secratKey.trim()).toString();
  //   return crpt;
  // }

  // decrypt(textToDecrypt: string) {
  //   let decp=CryptoJS.AES.decrypt(textToDecrypt, this.secratKey.trim()).toString(CryptoJS.enc.Utf8);
  //   return decp
  // }


  // encryptUsingAES256(value:string) {
  //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   this.encrypted = CryptoJS.AES.encrypt(
  //     JSON.parse(JSON.stringify(value)), _key, {
  //       keySize: 16,
  //       iv: _iv,
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7
  //     });
  //   return this.encrypted.toString();
  // }
  // decryptUsingAES256(value:string) {

  //   let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
  //   let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

  //   this.decrypted = CryptoJS.AES.decrypt(
  //     value, _key, {
  //       keySize: 16,
  //       iv: _iv,
  //       mode: CryptoJS.mode.ECB,
  //       padding: CryptoJS.pad.Pkcs7
  //     }).toString(CryptoJS.enc.Utf8);
  //     return  this.decrypted;
  // }
  encryptUsingAES256(text:any) {
    let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
    let encrypted = CryptoJS.AES.encrypt(
      JSON.stringify(text), _key, {
      keySize: 16,
      iv: _iv,
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7
    });
    return encrypted.toString();
  }
  decryptUsingAES256(encText:any) {
    if (encText != null) {
      let _key = CryptoJS.enc.Utf8.parse(this.tokenFromUI);
      let _iv = CryptoJS.enc.Utf8.parse(this.tokenFromUI);

      let decrypted = CryptoJS.AES.decrypt(
        encText, _key, {
        keySize: 16,
        iv: _iv,
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7
      }).toString(CryptoJS.enc.Utf8);
      return JSON.parse(decrypted);
    }
    return null;
  }
}
